import React from 'react';
import DocumentsModal from './documents_modal.js.jsx';

export default class NewDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    let selectedTemplate = this.props.document_templates[0];
    this.state = {
      selectedTemplateName: selectedTemplate.name,
      selectedTemplateID: selectedTemplate.id,
      submitted: false,
      acknowlegeDisabled: false,
    };

    this._handleCreate = this._handleCreate.bind(this);
    this._handlePreview = this._handlePreview.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleAcknowledge = this._handleAcknowledge.bind(this);
    this.templatesExist =
      this.props.document_templates && this.props.document_templates.length > 0;
  }

  componentDidMount() {
    initReactSelect2(
      '#mdc-dialog-new-document .pm-default-select2-menu',
      this._handleChange
    );
  }

  render() {
    if (this.state.submitted) {
      var message = `${this.state.selectedTemplateName} document is being created. A notification will appear once the document has finished generating.`;
      var mainView = (
        <MainView
          message={message}
          lease_id={this.props.lease_id}
          company_id={this.props.company_id}
          contact_id={this.props.contact_id}
          submitted={this.state.submitted}
        />
      );
      var buttonView = (
        <ButtonView
          submitted={this.state.submitted}
          lease_id={this.props.lease_id}
          company_id={this.props.company_id}
          contact_id={this.props.contact_id}
          _handleAcknowledge={this._handleAcknowledge}
          acknowlegeDisabled={this.state.acknowlegeDisabled}
        />
      );
    } else {
      var message = 'Select a document template';
      var mainView = (
        <MainView
          templateName={this.state.selectedTemplateName}
          _handleChange={this._handleChange}
          lease_id={this.props.lease_id}
          company_id={this.props.company_id}
          contact_id={this.props.contact_id}
          message={message}
          documentTemplatesInfo={this.props.document_templates_info}
          submitted={this.state.submitted}
          templatesExist={this.templatesExist}
          document_templates={this.props.document_templates}
          selectedTemplateID={this.state.selectedTemplateID}
        />
      );
      var buttonView = (
        <ButtonView
          submitted={this.state.submitted}
          lease_id={this.props.lease_id}
          company_id={this.props.company_id}
          contact_id={this.props.contact_id}
          _handlePreview={this._handlePreview}
          _handleCreate={this._handleCreate}
          templatesExist={this.templatesExist}
        />
      );
    }

    return (
      <aside
        id="mdc-dialog-new-document"
        className="mdc-dialog mdc-dialog--open"
        role="alertdialog"
        aria-hidden="true"
        aria-labelledby="mdc-dialog-default-label"
        aria-describedby="mdc-dialog-default-description"
      >
        <div id="new-document-dialog-surface" className="mdc-dialog__surface">
          <header className="mdc-dialog__header pm-bg-color--blue1">
            <h2
              id="mdc-dialog-default-label"
              className="mdc-dialog__header__title"
            >
              New Document
            </h2>
          </header>

          {mainView}
          {buttonView}
        </div>
        <div className="mdc-dialog__backdrop"></div>
      </aside>
    );
  }

  _handlePreview() {
    var actionURL;
    if (this.props.lease_id)
      actionURL = `/leases/${this.props.lease_id}/documents_preview_redesign`;
    else if (this.props.company_id)
      actionURL = `/companies/${this.props.company_id}/documents_preview_redesign`;
    else if (this.props.contact_id)
      actionURL = `/contacts/${this.props.contact_id}/documents_preview_redesign`;
    var previewURL;
    var documentTemplateData = {};
    var tempIdAttr;
    var tempVal;

    documentTemplateData[this.state.selectedTemplateID] = {};
    for (const j in this.props.document_templates_info[
      this.state.selectedTemplateID
    ]) {
      tempIdAttr =
        this.props.document_templates_info[this.state.selectedTemplateID][j]
          .id_attr;
      tempVal = $("[id='" + tempIdAttr + "']").val();
      const htmlField =
        this.props.document_templates_info[this.state.selectedTemplateID][j]
          .html;
      if (htmlField) {
        if (
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_html_fields'
          ] == null
        ) {
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_html_fields'
          ] = {};
        }
        documentTemplateData[this.state.selectedTemplateID][
          'dynamic_html_fields'
        ][
          this.props.document_templates_info[this.state.selectedTemplateID][
            j
          ].field_id
        ] = encodeURIComponent(tempVal);
      } else {
        if (
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_fields'
          ] == null
        ) {
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_fields'
          ] = {};
        }
        documentTemplateData[this.state.selectedTemplateID]['dynamic_fields'][
          this.props.document_templates_info[this.state.selectedTemplateID][
            j
          ].field_id
        ] = encodeURIComponent(tempVal);
      }
    }

    const urlParams = {
      template_id: this.state.selectedTemplateID,
      document_templates: documentTemplateData,
    };
    const previewUrl = `${actionURL}?${$.param(urlParams)}`;
    window.open(previewUrl);
  }

  _handleChange(event) {
    let name = event.target.childNodes[event.target.selectedIndex].textContent;
    this.setState({
      selectedTemplateID: event.target.value,
      selectedTemplateName: name,
    });
  }

  _handleCreate() {
    startLoadingSpinner('new-document-dialog-surface');
    var documentTemplateData = {};
    var tempIdAttr;
    var tempVal;

    documentTemplateData[this.state.selectedTemplateID] = {};
    for (const j in this.props.document_templates_info[
      this.state.selectedTemplateID
    ]) {
      tempIdAttr =
        this.props.document_templates_info[this.state.selectedTemplateID][j]
          .id_attr;
      tempVal = $("[id='" + tempIdAttr + "']").val();
      const htmlField =
        this.props.document_templates_info[this.state.selectedTemplateID][j]
          .html;
      if (htmlField) {
        if (
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_html_fields'
          ] == null
        ) {
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_html_fields'
          ] = {};
        }
        documentTemplateData[this.state.selectedTemplateID][
          'dynamic_html_fields'
        ][
          this.props.document_templates_info[this.state.selectedTemplateID][
            j
          ].field_id
        ] = encodeURIComponent(tempVal);
      } else {
        if (
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_fields'
          ] == null
        ) {
          documentTemplateData[this.state.selectedTemplateID][
            'dynamic_fields'
          ] = {};
        }
        documentTemplateData[this.state.selectedTemplateID]['dynamic_fields'][
          this.props.document_templates_info[this.state.selectedTemplateID][
            j
          ].field_id
        ] = encodeURIComponent(tempVal);
      }
    }
    const that = this;
    var actionURL;
    if (this.props.lease_id)
      actionURL = `/leases/${this.props.lease_id}/documents_redesign`;
    else if (this.props.company_id)
      actionURL = `/companies/${this.props.company_id}/documents_redesign`;
    else if (this.props.contact_id)
      actionURL = `/contacts/${this.props.contact_id}/documents_redesign`;
    $.ajax({
      url: actionURL,
      type: 'POST',
      dataType: 'JSON',
      data: {
        template_id: this.state.selectedTemplateID,
        document_templates: documentTemplateData,
      },
      success: (data) => {
        if (data.errors === null || data.errors === undefined) {
          this.setState({ submitted: true });
        } else {
          alert(data.errors);
        }
      },
      complete: () => {
        stopLoadingSpinner('new-document-dialog-surface');
      },
    });
  }

  _handleAcknowledge() {
    startLoadingSpinner('new-document-dialog-surface');
    const that = this;
    var actionURL;
    if (this.props.lease_id)
      actionURL = `/leases/${this.props.lease_id}/documents_redesign`;
    else if (this.props.company_id)
      actionURL = `/companies/${this.props.company_id}/documents_redesign`;
    else if (this.props.contact_id)
      actionURL = `/contacts/${this.props.contact_id}/documents_redesign`;
    this.setState({ acknowlegeDisabled: true });
    $.ajax({
      url: actionURL,
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        data['parameters'] = {};
        data['parameters']['parentComponent'] = that.props.parentComponent;
        if (that.props.lease_id)
          data['parameters']['lease_id'] = that.props.lease_id;
        else if (that.props.company_id)
          data['parameters']['company_id'] = that.props.company_id;
        else if (that.props.contact_id)
          data['parameters']['contact_id'] = that.props.contact_id;
        stopLoadingSpinner('new-document-dialog-surface');
        initDialog(React.createElement(DocumentsModal, data), {
          cancelFunction: that.cancelDocumentModal,
          renderContentType: 'reactElement',
          dialogId: 'mdc-dialog-documents',
        });
      },
    });
  }

  cancelDocumentModal(closeDialogCallback) {
    closeDialogCallback();
  }
}

class TemplateOption extends React.Component {
  render() {
    const templateID = this.props.id;

    return <option value={templateID}>{this.props.name}</option>;
  }
}

class MainView extends React.Component {
  render() {
    const message = this.props.message;

    if (this.props.submitted) {
      // React doesn't detect the select menu after submission
      $('aside span.select2').remove();
      return (
        <section
          id="mdc-dialog-default-description"
          className="mdc-dialog__body mdc-dialog__body--scrollable"
        >
          <h3>{message}</h3>
        </section>
      );
    } else {
      const _handleChange = this.props._handleChange;
      const document_templates = this.props.document_templates;
      const selectedTemplateID = this.props.selectedTemplateID;

      if (this.props.templatesExist) {
        var templates = document_templates;
        var templateOptions = templates.map((template) => {
          return (
            <TemplateOption
              key={template.id}
              name={template.name}
              id={template.id}
            />
          );
        });
      } else {
        var templateOptions = (
          <option value="none">No templates available</option>
        );
      }

      var extraFieldsView = null;
      if (this.props.documentTemplatesInfo[this.props.selectedTemplateID]) {
        extraFieldsView = this.props.documentTemplatesInfo[
          this.props.selectedTemplateID
        ].map(function (field, idx) {
          return (
            <div key={idx} style={{ marginBottom: '1.125rem' }}>
              <div className="mdc-text-field mdc-text-field--multiline mdc-text-field--upgraded">
                <textarea
                  className="mdc-text-field__input"
                  name={field.name_attr}
                  id={field.id_attr}
                ></textarea>
                <label
                  htmlFor={field.id_attr}
                  className="mdc-floating-label mdc-floating-label--float-above"
                >
                  {field.name}
                </label>
              </div>
            </div>
          );
        });
      }

      var warningText;
      if (this.props.lease_id)
        warningText = `WARNING: This document will be emailed to the tenant(s).`;
      else if (this.props.company_id)
        warningText = `WARNING: This document will be emailed to the owners(s).`;
      else if (this.props.contact_id)
        warningText = `WARNING: This document will be emailed to the lead.`;

      return (
        <section
          id="mdc-dialog-default-description"
          className="mdc-dialog__body mdc-dialog__body--scrollable"
        >
          <h3>{message}</h3>
          <div className="pm-flex pm-flex-2">
            <div className="pm-flex pm-flex-col">
              <div className="pm-select">
                <label>Document Template</label>
                <select
                  className="pm-default-select2-menu mdc-select"
                  id="document_template"
                  name="document[template]"
                  value={selectedTemplateID}
                  onChange={_handleChange}
                  style={{ backgroundColor: 'transparent' }}
                >
                  {templateOptions}
                </select>
              </div>
            </div>
          </div>
          <p>{warningText}</p>
          {extraFieldsView != null ? (
            <h4>Please fill in the following dynamic document fields:</h4>
          ) : (
            ''
          )}
          {extraFieldsView}
        </section>
      );
    }
  }
}

class ButtonView extends React.Component {
  constructor() {
    super();
    this.state = {
      submitDisabled: false,
    };
    this._handleCreate = this._handleCreate.bind(this);
    this._handlePreview = this._handlePreview.bind(this);
  }

  _handleCreate(event) {
    const that = this;

    if (that.props.lease_id || that.props.company_id || that.props.contact_id) {
      var actionURL;
      if (that.props.lease_id)
        actionURL = `/leases/${that.props.lease_id}/documents_redesign`;
      else if (that.props.company_id)
        actionURL = `/companies/${that.props.company_id}/documents_redesign`;
      else if (that.props.contact_id)
        actionURL = `/contacts/${that.props.contact_id}/documents_redesign`;
      $.ajax({
        url: actionURL,
        type: 'GET',
        dataType: 'JSON',
        success: (data) => {
          if (data['data']['entitiesWithoutEmail'].length != 0) {
            confirm(
              `Are you sure you wish to continue? The following ${
                that.props.lease_id ? 'tenants' : 'owners'
              } do not have active emails and will not receive any notification: <br> ${data[
                'data'
              ]['entitiesWithoutEmail'].join(', ')}`,
              function (ok) {
                if (ok) {
                  event.preventDefault();
                  that.setState({
                    submitDisabled: true,
                  });
                  setTimeout(
                    () => that.setState({ submitDisabled: false }),
                    5000
                  );

                  that.props._handleCreate();
                }
              }
            );
          } else {
            event.preventDefault();
            that.setState({
              submitDisabled: true,
            });
            setTimeout(() => that.setState({ submitDisabled: false }), 5000);

            that.props._handleCreate();
          }
        },
      });
    } else {
      event.preventDefault();
      that.setState({
        submitDisabled: true,
      });
      setTimeout(() => that.setState({ submitDisabled: false }), 5000);

      that.props._handleCreate();
    }
  }

  _handlePreview(event) {
    event.preventDefault();
    this.setState({
      submitDisabled: true,
    });

    setTimeout(() => this.setState({ submitDisabled: false }), 5000);

    this.props._handlePreview();
  }

  render() {
    if (this.props.submitted) {
      const _handleAcknowledge = this.props._handleAcknowledge;
      const disabled = this.props.acknowlegeDisabled;
      return (
        <footer className="mdc-dialog__footer" id="test">
          <button
            type="button"
            className="mdc-button pm-text-color--blue1 mdc-dialog__footer__button mdc-dialog__footer__button--acceptPM"
            data-testing-id="acknowledge"
            onClick={_handleAcknowledge}
            disabled={disabled}
          >
            Acknowledge
          </button>
        </footer>
      );
    } else {
      const templatesExist = this.props.templatesExist;
      const buttonsDisabled = !templatesExist || this.state.submitDisabled;
      return (
        <footer className="mdc-dialog__footer" id="test">
          <button
            type="button"
            className="cancel-button mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--cancelPM"
          >
            Cancel
          </button>
          <button
            type="button"
            className="preview-button mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--previewPM"
            onClick={this._handlePreview}
            disabled={buttonsDisabled}
          >
            Preview
          </button>
          <button
            type="button"
            className="submit-button mdc-button pm-text-color--blue1 mdc-dialog__footer__button mdc-dialog__footer__button--acceptPM"
            onClick={this._handleCreate}
            disabled={buttonsDisabled}
          >
            Create
          </button>
        </footer>
      );
    }
  }
}
