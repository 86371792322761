import React from 'react';
import Document from './document_redesign.js.jsx';
import NewDocumentModal from './new_document_modal.js.jsx';

export default class DocumentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCompletedText: '',
      filterPendingText: '',
      documents: [],
      filteredDocuments: [],
      origin: props.origin || null,
      parameters: props.parameters || {},
      offset: props.offset || 0,
    };
    this.searchVal = '';
    this.searchxhr = null;
    this.thumbnailxhr = null;
    this.documents = [];
    this.loadingMoreDocuments = false;
    this._handleNewDocument = this._handleNewDocument.bind(this);
    this._getDocuments = this._getDocuments.bind(this);
    this._deleteDocument = this._deleteDocument.bind(this);
    this.submitNewDocumentModal = this.submitNewDocumentModal.bind(this);
    this.openDocumentsModal = this.openDocumentsModal.bind(this);
    this.handleFilterPendingTextInput =
      this.handleFilterPendingTextInput.bind(this);
    this.handleFilterCompletedTextInput =
      this.handleFilterCompletedTextInput.bind(this);
    this._fetchDocuments = this._fetchDocuments.bind(this);
    this.displayPendingDocuments = this.displayPendingDocuments.bind(this);
    this.displayCompletedDocuments = this.displayCompletedDocuments.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.loadThumbnails = this.loadThumbnails.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const that = this;
    startLoadingSpinner('documents-modal');
    this._fetchDocuments();

    // Ensure height of history panels are appropriate for each document
    var historyHeight = document.getElementsByClassName(
      'pm-doclist--history-inner'
    );
    function checkHistoryHeight() {
      for (var i = 0; i < historyHeight.length; i++) {
        var height = historyHeight[i].offsetHeight;
        historyHeight[i].parentNode.style.height = remAdjustedValue(height);
      }
    }
    checkHistoryHeight();

    $('#mdc-dialog-documents .mdc-tab').click(function () {
      $('#mdc-dialog-documents .mdc-tab').css('border-bottom', 'none');
      $(this).css('border-bottom', '0.1875rem solid #2177d7');
    });

    $('#mdc-dialog-documents .mdc-tab--active').click();

    $('#documents-modal').scroll(that.handleScroll);

    if (
      this.props.parameters != null &&
      this.props.parameters.parentComponent != null
    ) {
      this.props.parameters.parentComponent.reloadDocumentsModal = (
        loadMore,
        loadThumbnails
      ) => {
        that.setState({ offset: 0 }, () => {
          that._fetchDocuments(loadMore, loadThumbnails);
        });
      };
    }
  }

  componentWillUnmount() {
    clearInterval(this._timer);
  }

  render() {
    const that = this;
    const documents = this._getDocuments();
    const pendingDocs = documents['pending'];
    const completedDocs = documents['completed'];

    var searchBar1 = (
      <DocumentSearchBar
        searchName={'pending'}
        handleClearSearch={this.handleClearSearch}
        onFilterTextInput={this.handleFilterPendingTextInput}
      />
    );
    var searchBar2 = (
      <DocumentSearchBar
        searchName={'completed'}
        handleClearSearch={this.handleClearSearch}
        onFilterTextInput={this.handleFilterCompletedTextInput}
      />
    );

    return (
      <aside
        id="mdc-dialog-documents"
        className="mdc-dialog mdc-dialog--open"
        role="alertdialog"
        aria-hidden="true"
        aria-labelledby="mdc-dialog-default-label"
        aria-describedby="mdc-dialog-default-description"
      >
        <div className="mdc-dialog__surface" id="documents-section">
          <header className="mdc-dialog__header pm-bg-color--blue1">
            <h2
              id="mdc-dialog-default-label"
              className="mdc-dialog__header__title"
            >
              Documents
            </h2>
          </header>
          <section
            id="documents-modal"
            className="mdc-dialog__body mdc-dialog__body--scrollable"
            style={{ paddingBottom: '0.625rem', paddingTop: '1.25rem' }}
          >
            {/* Tabs that separate pending and completed documents  */}
            {!this.props.budgetsPage && (
              <div className="pm-padding-mobile-none pm-border-bottom--black12">
                <div
                  id="tab-bar-scroller--1"
                  className="mdc-tab-bar-scroller pm-flex pm-flex-grow"
                >
                  <div className="mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--back pm-text-color--grey6">
                    <a
                      className="mdc-tab-bar-scroller__indicator__inner material-icons"
                      aria-label="scroll back button"
                    >
                      navigate_before
                    </a>
                  </div>
                  <div className="mdc-tab-bar-scroller__scroll-frame">
                    <nav
                      id="dynamic-tab-bar"
                      className="pm-dynamic-tabs mdc-tab-bar mdc-tab-bar-scroller__scroll-frame__tabs"
                    >
                      <a
                        className="mdc-tab mdc-tab--active"
                        aria-controls="panel-1"
                        onClick={this.displayPendingDocuments}
                      >
                        Pending
                      </a>
                      <a
                        className="mdc-tab"
                        aria-controls="panel-2"
                        onClick={this.displayCompletedDocuments}
                      >
                        Completed
                      </a>
                      <span className="mdc-tab-bar__indicator"></span>
                    </nav>
                  </div>
                  <div className="mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--forward pm-text-color--grey6">
                    <a
                      className="mdc-tab-bar-scroller__indicator__inner material-icons"
                      aria-label="scroll forward button"
                    >
                      navigate_next
                    </a>
                  </div>
                </div>
              </div>
            )}
            {this.props.budgetsPage && (
              <div className="mdc-tab-bar">
                <div className="mdc-tab-scroller">
                  <div className="mdc-tab-scroller__scroll-area mdc-tab-scroller__scroll-area--scroll">
                    <div className="mdc-tab-scroller__scroll-content">
                      <button
                        className="mdc-tab"
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        style={{
                          flex: 'unset',
                          borderBottom: '0.1875rem solid rgb(33, 119, 215)',
                        }}
                      >
                        <span className="mdc-tab__content">
                          <a
                            className="mdc-tab__text-label"
                            style={{ color: '#91A0AC' }}
                          >
                            Pending
                          </a>
                        </span>
                        <span className="mdc-tab-indicator">
                          <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
                        </span>
                        <a
                          className="mdc-tab__ripple mdc-ripple-upgraded"
                          onClick={this.displayPendingDocuments}
                        ></a>
                      </button>

                      <button
                        className="mdc-tab"
                        role="tab"
                        aria-selected="true"
                        tabIndex="0"
                        style={{ flex: 'unset' }}
                      >
                        <span className="mdc-tab__content">
                          <span
                            className="mdc-tab__text-label"
                            style={{ color: '#91A0AC' }}
                          >
                            Completed
                          </span>
                        </span>
                        <span className="mdc-tab-indicator">
                          <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
                        </span>
                        <a
                          className="mdc-tab__ripple mdc-ripple-upgraded"
                          onClick={this.displayCompletedDocuments}
                        ></a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className="pm-dynamic-panel active"
              id="panel-1"
              role="tabpanel"
              aria-hidden="false"
              style={{ paddingTop: 0 }}
            >
              <div className="pm-document-header-flex pm-flex">
                <h2>Documents Awaiting Signatures</h2>
                <div className="pm-flex-grow"></div>
                {this.props.origin != 'navbar' && (
                  <button
                    type="button"
                    data-testing-id="new-document"
                    className="mdc-button mdc-button--raised pm-bg-color--blue1 pm-text-color--white pm-margin-bottom-16"
                    onClick={that._handleNewDocument}
                  >
                    New Document
                  </button>
                )}
              </div>
              {searchBar1}

              <div className="pm-doclist">{pendingDocs}</div>
            </div>

            <div
              className="pm-dynamic-panel"
              id="panel-2"
              role="tabpanel"
              aria-hidden="true"
              style={{ paddingTop: 0 }}
            >
              <div className="pm-document-header-flex pm-flex">
                <h2>Signatures</h2>
                <div className="pm-flex-grow"></div>
                {this.props.origin != 'navbar' && (
                  <button
                    type="button"
                    data-testing-id="new-document"
                    className="mdc-button mdc-button--raised pm-bg-color--blue1 pm-text-color--white pm-margin-bottom-16"
                    onClick={that._handleNewDocument}
                  >
                    New Document
                  </button>
                )}
              </div>

              {searchBar2}

              <div className="pm-doclist">{completedDocs}</div>
            </div>
          </section>
          <footer className="mdc-dialog__footer">
            <button
              type="button"
              className="documents-cancel-button mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--cancelPM"
            >
              Cancel
            </button>
          </footer>
        </div>
        <div className="mdc-dialog__backdrop"></div>
      </aside>
    );
  }

  _getDocuments() {
    let documents = { pending: [], completed: [] };
    this.state.documents.forEach((document) => {
      if (
        document['status'] != 'Completed' &&
        (document.name
          .toLowerCase()
          .indexOf(this.state.filterPendingText.toLowerCase()) != -1 ||
          this.state.filterPendingText === '')
      ) {
        documents['pending'].push(
          <Document
            id={document.id}
            key={document.id}
            parent={this}
            doc={document}
            name={document.name}
            status={document.status}
            sign_option={document.sign_option}
            created_at={document.created_at}
            thumbnail={''}
            attachment_URL={''}
            audit_history={document.audit_history}
            onDelete={this._deleteDocument}
            budgetsPage={this.props.budgetsPage}
            sharedWithOwner={document.shared_with_owner}
            sharedWithTenant={document.shared_with_tenant}
          />
        );
      }
      if (
        document['status'] == 'Completed' &&
        (document.name
          .toLowerCase()
          .indexOf(this.state.filterCompletedText.toLowerCase()) != -1 ||
          this.state.filterCompletedText === '')
      ) {
        documents['completed'].push(
          <Document
            id={document.id}
            key={document.id}
            parent={this}
            doc={document}
            name={document.name}
            status={document.status}
            created_at={document.created_at}
            thumbnail={''}
            attachment_URL={''}
            audit_history={document.audit_history}
            onDelete={this._deleteDocument}
            budgetsPage={this.props.budgetsPage}
            sharedWithOwner={document.shared_with_owner}
            sharedWithTenant={document.shared_with_tenant}
          />
        );
      }
    });
    return documents;
  }

  _deleteDocument(doc) {
    startLoadingSpinner('documents-section');
    var actionURL = `/documents/index_redesign/${doc.id}`;
    if (this.state.origin != 'navbar') {
      if (
        this.props.parameters &&
        this.props.parameters.lease_id !== undefined
      ) {
        actionURL = `/leases/${this.props.parameters.lease_id}/documents_redesign/${doc.id}`;
      } else if (
        this.props.parameters &&
        this.props.parameters.company_id !== undefined
      ) {
        actionURL = `/companies/${this.props.parameters.company_id}/documents_redesign/${doc.id}`;
      } else if (
        this.props.parameters &&
        this.props.parameters.contact_id != null
      ) {
        actionURL = `/contacts/${this.props.parameters.contact_id}/documents_redesign/${doc.id}`;
      }
    }
    const that = this;
    $.ajax({
      method: 'DELETE',
      url: actionURL,
      dataType: 'JSON',
      success: function (data) {
        if (data.errors !== undefined && data.errors !== null) {
          alert(data.errors);
        } else {
          const documents = [...that.state.documents];
          const docIndex = documents.indexOf(doc);
          documents.splice(docIndex, 1);

          that.setState({ documents });
        }
        stopLoadingSpinner('documents-section');
      },
    });
  }

  _handleNewDocument() {
    const that = this;

    var actionURL;
    if (this.props.parameters && this.props.parameters.lease_id != null)
      actionURL = `/leases/${this.props.parameters.lease_id}/documents/new_redesign`;
    else if (this.props.parameters && this.props.parameters.company_id != null)
      actionURL = `/companies/${this.props.parameters.company_id}/documents/new_redesign`;
    else if (this.props.parameters && this.props.parameters.contact_id != null)
      actionURL = `/contacts/${this.props.parameters.contact_id}/documents/new_redesign`;

    $.ajax({
      url: actionURL,
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        data['parentComponent'] = that.props.parameters.parentComponent;
        initDialog(React.createElement(NewDocumentModal, data), {
          acceptFunction: that.submitNewDocumentModal,
          cancelFunction: that.openDocumentsModal,
          renderContentType: 'reactElement',
          dialogId: 'mdc-dialog-new-document',
        });
      },
    });
  }

  submitNewDocumentModal(closeDialogCallback) {
    enableSubmit();
    // NewDocumentModal will handle this
  }

  openDocumentsModal(closeDialogCallback) {
    const that = this;
    var actionURL;

    if (this.props.parameters && this.props.parameters.lease_id != null)
      actionURL = `/leases/${this.props.parameters.lease_id}/documents_redesign`;
    else if (this.props.parameters && this.props.parameters.company_id != null)
      actionURL = `/companies/${this.props.parameters.company_id}/documents_redesign`;
    else if (this.props.parameters && this.props.parameters.contact_id != null)
      actionURL = `/contacts/${this.props.parameters.contact_id}/documents_redesign`;
    else actionURL = '/documents/index_redesign';
    $.ajax({
      url: actionURL,
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        data['parameters']['parentComponent'] =
          that.props.parameters.parentComponent;
        initDialog(React.createElement(DocumentsModal, data), {
          cancelFunction: that.cancelDocumentModal,
          renderContentType: 'reactElement',
          dialogId: 'mdc-dialog-documents',
        });
      },
    });
  }

  cancelDocumentModal(closeDialogCallback) {
    closeDialogCallback();
  }

  handleFilterPendingTextInput(event) {
    const that = this;
    let filterText = $(event.target).val();
    that.searchVal = filterText;
    let actionURL = '/documents/index_redesign';
    if (that.props.parameters && that.props.parameters.lease_id !== undefined)
      actionURL =
        '/leases/' + that.props.parameters.lease_id + '/documents_redesign';
    else if (
      that.props.parameters &&
      that.props.parameters.company_id !== undefined
    )
      actionURL =
        '/companies/' +
        that.props.parameters.company_id +
        '/documents_redesign';
    else if (
      this.props.parameters &&
      this.props.parameters.contact_id !== undefined
    )
      actionURL = `/contacts/${this.props.parameters.contact_id}/documents_redesign`;
    startLoadingSpinner('documents-modal');

    if (that.state.canLoadMore) {
      if (
        typeof that.thumbnailxhr !== 'undefined' &&
        that.thumbnailxhr !== null &&
        that.thumbnailxhr.readyState !== 4
      ) {
        that.thumbnailxhr.abort();
      }
      if (
        typeof that.searchxhr !== 'undefined' &&
        that.searchxhr !== null &&
        that.searchxhr.readyState !== 4
      ) {
        that.searchxhr.abort();
      }

      that.searchxhr = $.ajax({
        url: actionURL,
        type: 'GET',
        dataType: 'JSON',
        data: { search_val: filterText, offset: 0 },
        success: function (data) {
          that.documents = [];
          that.setState(
            {
              documents: data.data.documents,
              canLoadMore: data.data.canLoadMore,
              offset: data.data.offset,
              filterPendingText: filterText,
            },
            function () {
              stopLoadingSpinner('documents-modal');
              that.loadThumbnails();
            }
          );
        },
      });
    } else {
      that.setState({ filterPendingText: filterText }, function () {
        stopLoadingSpinner('documents-modal');
      });
    }
  }

  handleFilterCompletedTextInput(event) {
    const that = this;
    let filterText = $(event.target).val();
    that.searchVal = filterText;
    let actionURL = '/documents/index_redesign';
    if (that.props.parameters && that.props.parameters.lease_id !== undefined)
      actionURL =
        '/leases/' + that.props.parameters.lease_id + '/documents_redesign';
    else if (
      that.props.parameters &&
      that.props.parameters.company_id !== undefined
    )
      actionURL =
        '/companies/' +
        that.props.parameters.company_id +
        '/documents_redesign';
    else if (
      this.props.parameters &&
      this.props.parameters.contact_id !== undefined
    )
      actionURL = `/contacts/${this.props.parameters.contact_id}/documents_redesign`;
    startLoadingSpinner('documents-modal');

    if (that.searchVal != '') {
      if (
        typeof that.thumbnailxhr !== 'undefined' &&
        that.thumbnailxhr !== null &&
        that.thumbnailxhr.readyState !== 4
      ) {
        that.thumbnailxhr.abort();
      }
      if (
        typeof that.searchxhr !== 'undefined' &&
        that.searchxhr !== null &&
        that.searchxhr.readyState !== 4
      ) {
        that.searchxhr.abort();
      }

      that.searchxhr = $.ajax({
        url: actionURL,
        type: 'GET',
        dataType: 'JSON',
        data: { search_val: filterText, offset: 0 },
        success: function (data) {
          that.documents = [];
          that.setState(
            {
              documents: data.data.documents,
              canLoadMore: data.data.canLoadMore,
              offset: data.data.offset,
              filterCompletedText: filterText,
            },
            function () {
              stopLoadingSpinner('documents-modal');
              that.loadThumbnails();
            }
          );
        },
      });
    } else {
      that.setState({ filterCompletedText: filterText }, function () {
        stopLoadingSpinner('documents-modal');
      });
    }
  }

  handleClearSearch(event) {
    const that = this;
    var searchName = event.target.dataset.searchName;
    var documents = this.state.documents.slice();
    $(event.target)
      .parents('#pm-multipay-search')
      .find('#multipay-search-input')
      .val('');
    let actionURL = '/documents/index_redesign';
    if (that.props.parameters && that.props.parameters.lease_id !== undefined)
      actionURL =
        '/leases/' + that.props.parameters.lease_id + '/documents_redesign';
    else if (
      that.props.parameters &&
      that.props.parameters.company_id !== undefined
    )
      actionURL =
        '/companies/' +
        that.props.parameters.company_id +
        '/documents_redesign';
    else if (
      this.props.parameters &&
      this.props.parameters.contact_id !== undefined
    )
      actionURL = `/contacts/${this.props.parameters.contact_id}/documents_redesign`;
    if (that.searchVal != '') {
      startLoadingSpinner('documents-modal');
      $.ajax({
        url: actionURL,
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
          that.searchVal = '';
          that.documents = [];
          if (searchName == 'pending')
            that.setState(
              {
                documents: data.data.documents,
                canLoadMore: data.data.canLoadMore,
                offset: data.data.offset,
                filterPendingText: '',
              },
              function () {
                stopLoadingSpinner('documents-modal');
                that.loadThumbnails();
              }
            );
          else
            that.setState(
              {
                documents: data.data.documents,
                canLoadMore: data.data.canLoadMore,
                offset: data.data.offset,
                filterCompletedText: '',
              },
              function () {
                stopLoadingSpinner('documents-modal');
                that.loadThumbnails();
              }
            );
        },
      });
    }
  }

  loadThumbnails() {
    var that = this;
    if (that.documents.length > 0) {
      that.thumbnailxhr = $.ajax({
        url:
          '/documents/' +
          that.documents[0].props.id +
          '/load_thumbnail_redesign',
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
          if (that.documents[0]) {
            that.documents[0].updateDocument(data);
          }
        },
        complete: function () {
          if (that.documents[0] != null) {
            stopLoadingSpinner('thumb-image-' + that.documents[0].props.id);
          } else {
            stopLoadingSpinner('documents-modal');
          }
          that.documents.shift();
          if (
            $('#mdc-dialog-documents').length > 0 &&
            $('#mdc-dialog-documents:visible').length > 0
          )
            that.loadThumbnails();
        },
      });
    }
  }

  _fetchDocuments(loadMore = false, loadThumbnails = true) {
    var that = this;
    var actionURL = '/documents/index_redesign';
    this.loadingMoreDocuments = true;
    startLoadingSpinner('documents-modal');
    if (this.state.origin != 'navbar') {
      if (this.props.parameters && this.props.parameters.lease_id !== undefined)
        actionURL =
          '/leases/' + this.props.parameters.lease_id + '/documents_redesign';
      else if (
        this.props.parameters &&
        this.props.parameters.company_id !== undefined
      )
        actionURL =
          '/companies/' +
          this.props.parameters.company_id +
          '/documents_redesign';
      else if (
        this.props.parameters &&
        this.props.parameters.contact_id !== undefined
      )
        actionURL =
          '/contacts/' +
          this.props.parameters.contact_id +
          '/documents_redesign';
    }

    var data = {
      origin: this.state.origin,
      offset: this.state.offset,
      search_val: this.searchVal,
    };

    $.ajax({
      method: 'GET',
      url: actionURL,
      dataType: 'JSON',
      data: data,
      success: (data) => {
        let docs = data.data.documents;
        if (loadMore) {
          docs = that.state.documents.slice().concat(docs);
        }
        that.setState(
          {
            documents: docs,
            filteredDocuments: data.data.documents,
            offset: data.data.offset,
            canLoadMore: data.data.canLoadMore,
          },
          function () {
            stopLoadingSpinner('documents-modal');
            this.loadingMoreDocuments = false;
            if (loadThumbnails) {
              that.loadThumbnails();
            }
          }
        );
      },
      complete: function () {
        stopLoadingSpinner('documents-modal');
        if (loadThumbnails) {
          $('.pm-doclist--thumb', '#mdc-dialog-documents').each(function () {
            startLoadingSpinner($(this).attr('id'));
          });
        }
      },
    });
  }

  handleScroll() {
    if (!this.state.canLoadMore) {
      return;
    }

    let lastDoc = $('#documents-modal .pm-doclist--doc:visible').last();

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(lastDoc).offset().top;
    var elemBottom = elemTop + $(lastDoc).height();

    if (
      elemBottom <= docViewBottom &&
      elemTop >= docViewTop &&
      !this.loadingMoreDocuments &&
      this.state.canLoadMore
    ) {
      this.loadingMoreDocuments = true;
      this._fetchDocuments(true);
    }
  }

  displayPendingDocuments(e) {
    var target = e.target;
    $('.mdc-tab.mdc-tab--active', '#mdc-dialog-documents').removeClass(
      'mdc-tab--active'
    );
    $(target).addClass('mdc-tab--active');
    $('#panel-2', '#mdc-dialog-documents').hide();
    $('#panel-1', '#mdc-dialog-documents').show();
  }

  displayCompletedDocuments(e) {
    var target = e.target;
    $('.mdc-tab.mdc-tab--active', '#mdc-dialog-documents').removeClass(
      'mdc-tab--active'
    );
    $(target).addClass('mdc-tab--active');
    $('#panel-1', '#mdc-dialog-documents').hide();
    $('#panel-2', '#mdc-dialog-documents').show();
  }
}

class DocumentSearchBar extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <div
          className="pm-sidebar-search"
          id="pm-multipay-search"
          style={{ height: '2.5rem' }}
        >
          <input
            type="text"
            id="multipay-search-input"
            placeholder="Search"
            onKeyUp={this.props.onFilterTextInput.bind(this)}
          />
          <label htmlFor="pm-sidebar-search-input1">
            <i className="mdi mdi-magnify"></i>
            <i
              className="mdi mdi-close pm-reset-input"
              id="documents-search-clear"
              data-search-name={this.props.searchName}
              onClick={this.props.handleClearSearch.bind(this)}
            ></i>
          </label>
        </div>
      </div>
    );
  }
}
